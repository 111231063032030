html {
    --scroll-behavior: smooth;
    scroll-behavior: smooth;
}

.AnotherContent {
    background-color: white;
}

.AnotherContent > *:nth-child(even) {
    background: #fffbf7;
}

.BlockWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vmax 0;
}

.BlockTitle {
    color: #221508;
    font-family: 'TildaSansMedium', sans-serif;
    font-size: clamp(1.5rem, 1.1538rem + 1.5385vw, 3rem);
    line-height: 110%;
    font-weight: 700;
    margin-bottom: 3vmax;
    margin-top: 0;
}

.ScrollTop {
    position: fixed;
    right: clamp(1rem, 0.8846rem + 0.5128vw, 1.5rem);
    bottom: clamp(1rem, 0.8846rem + 0.5128vw, 1.5rem);
    cursor: pointer;
    z-index: 5000;
    text-decoration: none;
}

.BlockDescriptionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BlockDescription {
    font-size: clamp(1.1rem, 1.0308rem + 0.3077vw, 1.4rem);
    line-height: 1.55;
    color: #221508;
    font-weight: 300;
    font-family: TildaSansLight, sans-serif;
    max-width: 900px;
    padding: 0 16px;
    text-indent: 3vmax;
    text-align: justify;
}

.ScrollTop:focus {
    outline: none !important;
}

.ScrollTop > svg {
    width: clamp(2.5rem, 2.3846rem + 0.5128vw, 3rem);
    height: clamp(2.5rem, 2.3846rem + 0.5128vw, 3rem);
    background: #ff5500;
    border-radius: 100%;
}
